import React from 'react'

class UserPage extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.location.href = '/article/measure-23-app-tutorial/';
    }
  }

  render() {
    return (<div>

    </div>)
  }

}

export default UserPage
